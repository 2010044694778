export const useMask = () => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    useGrouping: false,
  });

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: false,
  });

  const brlMask = (value) => formatter.format(value);

  const usdMask = (value) => usdFormatter.format(value);

  const numberMask = (value) => value.replace(/\D/g, '');
  const alphanumMask = (value) =>
    value.replace(/[^0-9a-zA-Z\u00C0-\u00FF\s]/g, '').replace(/\s{2,}/, ' ');

  const alphabetMask = (value) =>
    value.replace(/[^a-zA-Z\u00C0-\u00FF\s]/g, '').replace(/\s{2,}/, ' ');

  const emailMask = (value) =>
    value
      .replace(/[^a-zA-Z0-9._+-@]/g, '')
      .toLowerCase()
      .trim();

  const currentMonth = new Date().getMonth();
  const currentYear = parseInt(String(new Date().getFullYear()).slice(2));
  const maxYear = currentYear + 10;

  const birthDateMask = (value) => {
    if (value.length === 10) {
      const newDate = new Date(value);
      const minYear = 1900;
      const maxYear = new Date().getFullYear() - 16;
      return Object.prototype.toString.call(newDate) === '[object Date]' &&
        !isNaN(newDate.getTime())
        ? newDate.getFullYear() >= minYear && newDate.getFullYear() <= maxYear
          ? value
          : ''
        : '';
    }
    let newValue = numberMask(value);
    newValue = newValue.substring(0, 10);
    newValue = newValue.replace(/^(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
    return newValue;
  };
  const expireAtMask = (value) => {
    let newValue = numberMask(value);

    if (newValue.length === 2) {
      const month = parseInt(newValue);
      newValue = month > 0 && month < 13 ? newValue : '';
    }

    if (newValue.length === 4) {
      const month = parseInt(newValue.substring(0, 2));
      const year = parseInt(newValue.slice(2));
      newValue =
        month < currentMonth && year === currentYear
          ? ''
          : year >= currentYear && year <= maxYear
          ? newValue
          : newValue.substring(0, 2);
    }
    newValue = newValue.substring(0, 4);
    newValue = newValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
    return newValue;
  };

  const zipCodeMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');

    // Aplica a máscara
    return cleanedValue.replace(
      /(\d{5})(\d{0,3}).*/,
      (match, group1, group2) => {
        let maskedCep = '';

        maskedCep += group1;

        if (group2) {
          maskedCep += '-' + group2;
        }

        return maskedCep;
      },
    );
  };

  const cpfMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    return cleanedValue.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/,
      (match, group1, group2, group3, group4) => {
        let maskedCpf = '';

        if (group1) {
          maskedCpf += group1;
          if (group1.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group2) {
          maskedCpf += group2;
          if (group2.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group3) {
          maskedCpf += group3;
          if (group3.length === 3) {
            maskedCpf += '-';
          }
        }

        if (group4) {
          maskedCpf += group4;
        }

        return maskedCpf;
      },
    );
  };
  const cnpjMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');

    return cleanedValue.replace(
      /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2}).*/,
      (match, group1, group2, group3, group4, group5) => {
        let maskedCpf = '';

        if (group1) {
          maskedCpf += group1;
          if (group1.length === 2) {
            maskedCpf += '.';
          }
        }

        if (group2) {
          maskedCpf += group2;
          if (group2.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group3) {
          maskedCpf += group3;
          if (group3.length === 3) {
            maskedCpf += '/';
          }
        }

        if (group4) {
          maskedCpf += group4;
          if (group4.length === 4) {
            maskedCpf += '-';
          }
        }
        if (group5) {
          maskedCpf += group5;
        }

        return maskedCpf;
      },
    );
  };
  const documentMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length > 11) {
      return cleanedValue.replace(
        /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2}).*/,
        (match, group1, group2, group3, group4, group5) => {
          let maskedCpf = '';

          if (group1) {
            maskedCpf += group1;
            if (group1.length === 2) {
              maskedCpf += '.';
            }
          }

          if (group2) {
            maskedCpf += group2;
            if (group2.length === 3) {
              maskedCpf += '.';
            }
          }

          if (group3) {
            maskedCpf += group3;
            if (group3.length === 3) {
              maskedCpf += '/';
            }
          }

          if (group4) {
            maskedCpf += group4;
            if (group4.length === 4) {
              maskedCpf += '-';
            }
          }
          if (group5) {
            maskedCpf += group5;
          }

          return maskedCpf;
        },
      );
    }

    return cleanedValue.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/,
      (match, group1, group2, group3, group4) => {
        let maskedCpf = '';

        if (group1) {
          maskedCpf += group1;
          if (group1.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group2) {
          maskedCpf += group2;
          if (group2.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group3) {
          maskedCpf += group3;
          if (group3.length === 3) {
            maskedCpf += '-';
          }
        }

        if (group4) {
          maskedCpf += group4;
        }

        return maskedCpf;
      },
    );
  };

  const maskMobileOnInput = (match, p1, p2, p3) => {
    const part1 = p1 ? `(${p1})` : '';
    const part2 = p2 ? ` ${p2}` : '';
    const part3 = p3 ? `-${p3}` : '';
    return `${part1}${part2}${part3}`;
  };

  const mobileMask = (value) => {
    let newValue = numberMask(value);
    return newValue.replace(/(\d{2})(\d{0,5})(\d{0,4}).*/, maskMobileOnInput);
  };

  const onDelete = (e, value) => {
    const pressDelete =
      (e.key === 'Backspace' || e.key === 'Delete') && value.length > 0;
    return pressDelete ? value.slice(0, -1) : value;
  };

  const emailAddressMask = (value) =>
    value
      .replace(/[^a-zA-Z0-9._+-]/g, '')
      .toLowerCase()
      .trim();

  return {
    alphabetMask,
    numberMask,
    emailMask,
    brlMask,
    expireAtMask,
    zipCodeMask,
    documentMask,
    mobileMask,
    onDelete,
    emailAddressMask,
    birthDateMask,
    alphanumMask,
    usdMask,
    cpfMask,
    cnpjMask,
  };
};

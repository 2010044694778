import { cnpj, cpf } from 'cpf-cnpj-validator';

export const useError = () => {
  const isEmpty = (value) => value.trim() === '';

  const isEmptyOtherCategory = (selectedOption, value) =>
    selectedOption === 'Outros' && value?.trim() === '';

  const zipCodeRegex = /^\d{5}-\d{3}$/;
  const isNotZipCode = (value) => !zipCodeRegex.test(value);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const unidadeKumonRegex = /^[a-zA-Z0-9._%+-]+@unidadekumon\.com\.br$/;
  const unidadKumonRegex = /^[a-zA-Z0-9._%+-]+@unidadkumon\.com$/;

  const isKumonEmail = (email, country) => {
    const validate = {
      BRA: (value) => unidadeKumonRegex.test(value),
      ARG: (value) => unidadKumonRegex.test(value),
      BOL: (value) => unidadKumonRegex.test(value),
      CHL: (value) => unidadKumonRegex.test(value),
      COL: (value) => unidadKumonRegex.test(value),
      PER: (value) => unidadKumonRegex.test(value),
      URY: (value) => unidadKumonRegex.test(value),
    };
    return !validate[country](email);
  };
  const isEmail = (value) => !emailRegex.test(value);

  const mobileRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;
  const isMobile = (value) => {
    return !mobileRegex.test(value);
  };

  const calcDigit = (numbers, length, verifyingDigit = 0) => {
    const sum = numbers.reduce(
      (previous, current, index) =>
        parseInt(previous) + parseInt(current) * (length - index),
      0,
    );
    return ((verifyingDigit * 9 + sum) % 11) % 10;
  };

  const validateCpf = (cpfStr) => {
    cpfStr = cpfStr.replace(/\D/g, '');
    const elevenNumbers = cpfStr.length === 11;
    if (!elevenNumbers) {
      return true;
    }

    const regex = /^(\d)\1{10}$/;
    const repeatedNumbers = regex.test(cpfStr);
    if (repeatedNumbers) {
      return true;
    }

    const verifyingDigit = cpfStr.substring(9, 11);
    const numbers = cpfStr.substring(0, 9).split('').reverse();
    const firstDigit = calcDigit(numbers, 9);
    const secondDigit = calcDigit(numbers, 8, firstDigit);
    return verifyingDigit !== `${firstDigit}${secondDigit}`;
  };

  const validateCreditCardNumber = (value) => {
    if ('' === value || undefined === value || null === value) return false;
    const test = '4111111111111111';

    if (test === value) return true;

    value = value.replace(/\D/g, '');
    var nCheck = 0,
      nDigit = 0,
      bEven = false;

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  };

  const cvvCodeRegex = /^\d{3,4}$/;

  const isNotCvv = (value) => !cvvCodeRegex.test(value);

  const isBirthDate = (value) => {
    const newDate = new Date(value);
    return !(
      Object.prototype.toString.call(newDate) === '[object Date]' &&
      !isNaN(newDate.getTime())
    );
  };

  const isValidDate = (value) => {
    const newDate = new Date(value);
    const minDate = new Date('1923-01-01');
    const maxDate = new Date('2024-12-31');

    return newDate > minDate && newDate < maxDate;
  };

  const attendeeError = {
    name: {
      1: {
        func: (attendee, index) => isEmpty(attendee.name),
      },
    },
    lastname: {
      1: {
        func: (attendee, index) => isEmpty(attendee.lastname),
      },
    },
    documenttype: {
      1: {
        func: (attendee, index) => {
          return isEmpty(attendee.documenttype);
        },
      },
    },
    document: {
      1: {
        func: (attendee, index) => {
          if (attendee.documenttype === 'CPF') {
            return !cpf.isValid(attendee.document);
          }
          if (attendee.documenttype === 'CNPJ') {
            return !cnpj.isValid(attendee.document);
          }
          return isEmpty(attendee.document);
        },
      },
    },
    location: {
      1: {
        func: (attendee, index) => isEmpty(attendee.location),
      },
    },
    city: {
      1: {
        func: (attendee, index) => isEmpty(attendee.city),
      },
    },
    // birthdate: {
    //   1: {
    //     func: (attendee) => {
    //       return !isValidDate(attendee.birthdate);
    //     },
    //   },
    // },
    email: {
      1: {
        func: (attendee, index) => {
          return isEmail(attendee.email);
        },
      },
    },

    phone: {
      1: {
        func: (attendee, index) => {
          return isEmpty(attendee.phone);
        },
      },
    },
    dentalmedicine: {
      1: {
        func: (attendee, index) => {
          if (index === 0) {
            return false;
          }
          return isEmpty(attendee.dentalmedicine);
        },
      },
    },
    expertise: {
      1: {
        func: (attendee, index) => {
          return attendee.dentalmedicine === 'S' && isEmpty(attendee.expertise);
        },
      },
    },
    childunder2: {
      1: {
        func: (attendee, index) => {
          return isEmpty(attendee.childunder2);
        },
      },
    },
    childname: {
      1: {
        func: (attendee, index) => {
          return attendee.childunder2 === 'S' && isEmpty(attendee.childname);
        },
      },
    },
    childbirthdate: {
      1: {
        func: (attendee, index) => {
          return (
            attendee.childunder2 === 'S' &&
            !isValidDate(attendee.childbirthdate)
          );
        },
      },
    },
    childpassport: {
      1: {
        func: (attendee, index) => {
          return (
            attendee.childunder2 === 'S' && isEmpty(attendee.childpassport)
          );
        },
      },
    },
    recomendedby: {
      1: {
        func: (attendee, index) =>
          attendee.location !== 'OUT' && isEmpty(attendee.recomendedby),
      },
    },
    // howdidyouknow: {
    //   1: {
    //     func: (attendee) => {
    //       return isEmpty(attendee.howdidyouknow);
    //     },
    //   },
    // },
    // hotelperiod: {
    //   1: {
    //     func: (attendee) => {
    //       return (
    //         attendee.changehotelperiod === true && isEmpty(attendee.hotelperiod)
    //       );
    //     },
    //   },
    // },

    lgpdterm: {
      1: {
        func: (attendee, index) => {
          return attendee.lgpdterm === false;
        },
      },
    },
    // communicationterm: {
    //   1: {
    //     func: (attendee) => {
    //       return attendee.communicationterm === false;
    //     },
    //   },
    // },
  };

  const customerError = {
    name: {
      1: {
        func: (customer) => isEmpty(customer.name),
        msg: 'Em dados de cobrança, o campo nome não foi preenchido!',
      },
    },
    email: {
      1: {
        func: (customer) => {
          return isEmail(customer.email);
        },
        msg: 'Em dados de cobrança, o valor em e-mail é inválido!',
      },
    },
    address: {
      1: {
        func: (customer) => isEmpty(customer.address),
        msg: 'Em dados de cobrança, o campo nome não foi preenchido!',
      },
    },
  };

  const customerFreeError = {
    name: {
      1: {
        func: (customer) => isEmpty(customer.name),
        msg: 'Em dados de envio, o campo nome não foi preenchido!',
      },
    },
    email: {
      1: {
        func: (customer) => {
          return isEmail(customer.email);
        },
        msg: 'Em dados de envio, o valor em e-mail é inválido!',
      },
    },
  };
  const cctokenError = {
    number: {
      1: {
        func: (cctoken) => !validateCreditCardNumber(cctoken.number),
        msg: 'Em cartão de crédito, o valor do campo número do cartão é inválido!',
      },
    },
    name: {
      1: {
        func: (cctoken) => isEmpty(cctoken.name),
        msg: 'Em cartão de crédito, o campo nome impresso no cartão não foi preenchido!',
      },
    },
    country: {
      1: {
        func: (cctoken) => isEmpty(cctoken.country),
        msg: 'Em cartão de crédito, o campo nome impresso no cartão não foi preenchido!',
      },
    },
    expireAt: {
      1: {
        func: (cctoken) => isEmpty(cctoken.expireAt),
        msg: 'Em cartão de crédito, o campo data de validade não foi preenchido!',
      },
    },
    code: {
      1: {
        func: (cctoken) => isNotCvv(cctoken.code),
        msg: 'Em cartão de crédito, o valor do campo código é inválido!',
      },
    },
  };

  const loginError = {
    country: {
      1: {
        func: (login) => isEmpty(login.country),
      },
    },
    email: {
      1: {
        func: (login) => {
          return isKumonEmail(login.email, login.country);
        },
      },
    },
  };

  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    document.getElementById(id).focus();
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return {
    attendeeError,
    customerError,
    cctokenError,
    customerFreeError,
    loginError,
    goToFieldWithError,
    validateCreditCardNumber,
  };
};
